<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="form-title mt-0">Section 1: Select Fighter</h4>
            <div class="row">
              <label class="col-lg-2 col-form-label" for="simpleinput"
                >Fighter Name</label
              >
              <div class="col-lg-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.header.fighter_name"
                  value=""
                  disabled
                />
              </div>
              <div class="col-lg-2">
                <button
                  v-if="!this.$route.query.obj"
                  class="btn btn-primary"
                  @click="$refs.thaifighter.openModal()"
                  >Select</button
                >
              </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card-->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="form-title mt-0"
              >Section 2: Video List
              <button
                class="btn btn-primary pull-right"
                :disabled="Object.keys(form.header).length === 0"
                @click="$refs.videolist.openModal()"
                >Add Video</button
              ></h4
            >

            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Thumbnail</th>
                    <th scope="col">Video URL</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(x, idx) in form.detail" :key="x._id">
                    <th scope="row">{{ idx + 1 }}</th>
                    <td>
                      <img
                        style="height: 150px; width: 300px; "
                        :src="x.thumnail"
                      />
                    </td>
                    <td>{{ x.url }}</td>
                    <td>
                      <button
                        class="btn btn-xs btn-danger"
                        @click="delRow(x, idx)"
                        >Del</button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer">
            <div class="button-list pull-right">
              <button
                type="button"
                class="btn btn-outline-danger"
                @click="onClear"
                >Clear</button
              >
              <button type="button" class="btn btn-primary" @click="onSubmit"
                >Save</button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <ThaiFighterModal
      ref="thaifighter"
      @send-data="addThaifighter($event)"
    ></ThaiFighterModal>
    <VideoListModal
      ref="videolist"
      :fighter_id="this.form.header.id"
      @send-data="addVideo($event)"
    ></VideoListModal>
  </Layout>
</template>

<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import PageHeader from '@components/page-header'
import ThaiFighterModal from '@components/modal/thaifighter'
import VideoListModal from '@components/modal/video-list'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Create',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    ThaiFighterModal,
    VideoListModal,
  },
  data() {
    return {
      isEdit: false,
      title: 'Thai Fighter',
      items: [
        {
          text: 'Shreyu',
          href: '/',
        },
        {
          text: 'Pages',
          href: '/',
        },
        {
          text: 'Starter',
          active: true,
        },
      ],
      form: {
        header: {},
        detail: [],
      },
      data: [],
    }
  },

  methods: {
    async addThaifighter(e) {
      this.$set(this.form.header, 'id', e._id)
      this.$set(this.form.header, 'fighter_name', e.fighter_name)
      this.form.detail = []
    },
    async addVideo(e) {
      e.forEach((f) => {
        const found = this.form.detail.find((a) => a._id == f._id)

        if (!found) {
          this.form.detail.push(f)
        }
      })
    },
    delRow(x, idx) {
      this.form.detail.splice(idx, 1)
    },
    onClear() {
      this.form = {
        header: {},
        detail: [],
      }
    },
    async onSubmit() {
      if (this.$helpers.objectIsEmpty(this.form.header)) {
        this.$toast.error('Please select fighter')
        return
      }

      if (this.$helpers.objectIsEmpty(this.form.detail)) {
        this.$toast.error('Please select video')
        return
      }

      let video_id = []
      this.form.detail.map((x) => {
        video_id.push(x._id)
      })
      // console.log(this.form.header.id)
      const queryObj = this.$route.query.obj
      let data = {
        fighter_id: this.form.header.id,
        video_id: video_id,
        sub_id: this.$route.params.id,
      }
      if (queryObj) data.fighter_data_id = queryObj._id
      const request = !this.isEdit
        ? axios.post('/api/subcategory/addfighter', data)
        : axios.post('/api/subcategory/update/addfighter', data)
      await request
        .then((res) => {
          this.$router.back()
        })
        .catch((err) => {
          // console.log(err.response.data)
          alert(err.response.data.error.message)
        })
    },

    async initialUpdateItem() {
      const queryObj = this.$route.query.obj
      if (!queryObj) {
        return
      }
      // console.log(queryObj)
      this.form.header = {
        id: queryObj.fighter_id._id,
        fighter_name: queryObj.fighter_id.fighter_name,
      }
      this.form.detail = queryObj.video_data

      // let obj = await axios.get(`/api/category/getfighter/${queryid}`)
      // obj.data.map((x) => {
      //   this.value.push(x.fighter_id)
      // })
      // console.log(value)
      // this.value = obj.data
      this.isEdit = true
      // })
    },
  },
  mounted() {
    this.initialUpdateItem()
  },
}
</script>

<style scoped>
input[type='text']:disabled {
  background: #dddddd;
}
</style>
